import NotificationManager from '../../components/common/react-notifications/NotificationManager'

export const CUSTOM_PAGE_LOADING = 'VACANCY_LOADING'

export const setLoadingCustomPageAction = (loading) => (dispatch,) => dispatch({
	type: CUSTOM_PAGE_LOADING,
	loading,
},)

export const customPageCreateAction = (action, variables,) => async (dispatch,) => {
	try {
		dispatch(setLoadingCustomPageAction(true))
		const { data, } = await action({ variables, })
		dispatch(setLoadingCustomPageAction(false))
		return data.page.id
	} catch (e) {
		NotificationManager.error(
			e.message || '',
			'Проблема создания',
			3000,
			null,
			null,
		)
		dispatch(setLoadingCustomPageAction(false))
		return null
	}
}

export const customPageUpdateAction = (action, variables,) => async (dispatch,) => {
	try {
		dispatch(setLoadingCustomPageAction(true))
		const { data, } = await action({ variables, })
		return data.film
	} catch (e) {
		NotificationManager.error(
			e.message || '',
			'Проблема Обновления',
			3000,
			null,
			null,
		)
		dispatch(setLoadingCustomPageAction(false))
	}
	dispatch(setLoadingCustomPageAction(false))
}
