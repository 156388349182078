import NotificationManager from '../../components/common/react-notifications/NotificationManager'

export const PERSON_LOADING = 'PERSON_LOADING'

export const setLoadingPersonAction = (loading) => (dispatch,) => dispatch({
	type: PERSON_LOADING,
	loading,
},)

export const personCreateAction = (action, variables,) => async (dispatch,) => {
	try {
		dispatch(setLoadingPersonAction(true))
		const { data, } = await action({ variables, })
		dispatch(setLoadingPersonAction(false))
		return data.person
	} catch (e) {
		NotificationManager.error(
			e.message || '',
			'Проблема создания',
			3000,
			null,
			null,
		)
		dispatch(setLoadingPersonAction(false))
		return null
	}
}
