import { isPromo, portLink, promoLink, promoPort, requestRestLink } from '../utilits/envUtilit'

const url = isPromo ? `${promoLink}${promoPort}` : `${portLink}${requestRestLink}`
const responseHandler = async (response, resolve, reject,) => {
	try {
		const res = await response.json()
		const error = {
			code: 200,
			message: '',
		}
		if (response.status === 200) {
			resolve(res,)
		} else {
			if (response.status) error.code = response.status
			if (res.code) error.code = res.code
			if (res.message) error.message = res.message
			reject(error,)
		}
	} catch (err) {
		console.log(err,)
		reject({
			code: 600,
			message: 'Возникла проблема',
		},)
	}
}

// eslint-disable-next-line no-async-promise-executor
export const getLoginQuery = async ({login, password}) => new Promise(async (resolve, reject,) => {
	try {
		const response = await fetch(`${url}/login`, {
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'POST',
			body: JSON.stringify({login, password})
		},)
		await responseHandler(response, resolve, reject,)
	} catch (err) {
		console.log(err,)
		reject({
			code: 600,
			message: 'Возникла проблема',
		},)
	}
},)
