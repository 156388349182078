import storageHelper from '../../helpers/storageHelper'
import { getLoginQuery, } from '../../api/rest'
import clientGQL from '../../api/graphql'
import NotificationManager from '../../components/common/react-notifications/NotificationManager'
import { GET_CURRENT_USER_API, } from '../../api/users/usersQuery'
import { getTokenSelector, } from './selector'

export const AUTH_UPDATE = 'AUTH_UPDATE'
export const USER_UPDATE = 'USER_UPDATE'
export const AUTH_REMOVE = 'AUTH_REMOVE'
export const AUTH_LOADING = 'AUTH_LOADING'

export const updateAuthAction = (token, user) => (dispatch,) => {
	dispatch({
		type: AUTH_UPDATE,
		token,
	},)
	dispatch({
		type: USER_UPDATE,
		user,
	},)
}
export const updateUserAction = (user) => (dispatch,) => {
	storageHelper.set('__user__', user,)
	dispatch({
		type: USER_UPDATE,
		user,
	},)
}

export const removeAuthAction = () => (dispatch,) => {
	storageHelper.remove('__token__', 'cookie',)
	storageHelper.remove('__user__',)
	dispatch({
		type: AUTH_REMOVE,
	},)
}

export const setLoadingAuthAction = (loading) => (dispatch,) => dispatch({
	type: AUTH_LOADING,
	loading,
},)

export const userAuthAction = (variables,) => async (dispatch,) => {
	try {
		dispatch(setLoadingAuthAction(true))
		const content = await getLoginQuery(variables)
		storageHelper.set('__token__', content.token, 'cookie',)
		const { data, } = await clientGQL.query({
			query: GET_CURRENT_USER_API,
			notifyOnNetworkStatusChange: true,
			fetchPolicy: 'network-only',
		})
		dispatch(updateAuthAction(content.token, data.user))
	} catch (e) {
		NotificationManager.error(
			e.message || '',
			'Проблема авторизации',
			3000,
			null,
			null,
		)
		dispatch(setLoadingAuthAction(false))
	}
	dispatch(setLoadingAuthAction(false))
}

export const userGetAction = () => async (dispatch, getState) => {
	try {
		dispatch(setLoadingAuthAction(true))
		const token = getTokenSelector(getState())
		if (token) {
			const { data, } = await clientGQL.query({
				query: GET_CURRENT_USER_API,
				notifyOnNetworkStatusChange: true,
				fetchPolicy: 'network-only',
			})
			dispatch(updateUserAction(data.user))
		}
	} catch (e) {
		NotificationManager.error(
			e.message || '',
			'Проблема авторизации',
			3000,
			null,
			null,
		)
		dispatch(setLoadingAuthAction(false))
	}
	dispatch(setLoadingAuthAction(false))
}
