import { gql, } from 'apollo-boost'

export const GET_USERS_API = gql`
	query getUsers($from: Int!, $size: Int!, $nameOrEmail: String) {
		users: users(input: {nameOrEmail: $nameOrEmail}, pagination: {from: $from, size: $size}) {
			items {
				id
				name
				email
				role
				state
				createdAt
				updatedAt
				deletedAt
			}
			pagination {
				from
				size
				total
			}
		}
	}
`

export const GET_USER_ITEM_API = gql`
	query getItemUser($id: Int!) {
		user: user(id: $id) {
			id
			name
			email
			role
			state
			createdAt
			updatedAt
			deletedAt
		}
	}
`

export const GET_CURRENT_USER_API = gql`
	query getMiniForm {
		user: whoami {
			id
			email
			name
			role
		}
	}
`
