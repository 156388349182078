import NotificationManager from '../../components/common/react-notifications/NotificationManager'

export const USERS_LOADING = 'USERS_LOADING'

export const setLoadingUsersAction = (loading) => (dispatch,) => dispatch({
	type: USERS_LOADING,
	loading,
},)

export const usersCreateAction = (action, variables,) => async (dispatch,) => {
	try {
		dispatch(setLoadingUsersAction(true))
		const { data, } = await action({ variables, })
		dispatch(setLoadingUsersAction(false))
		NotificationManager.success(
			'Пароль отправлен на email',
			'Пользователь создан!',
			3000,
			null,
			null,
		)
		return data.user.id
	} catch (e) {
		NotificationManager.error(
			e.message || '',
			'Проблема создания',
			3000,
			null,
			null,
		)
		dispatch(setLoadingUsersAction(false))
		return null
	}
}

export const usersUpdateAction = (action, variables,) => async (dispatch,) => {
	try {
		dispatch(setLoadingUsersAction(true))
		const { data, } = await action({ variables, })
		return data.user
	} catch (e) {
		NotificationManager.error(
			e.message || '',
			'Проблема Обновления',
			3000,
			null,
			null,
		)
		dispatch(setLoadingUsersAction(false))
	}
	dispatch(setLoadingUsersAction(false))
}
