import {
	PERSON_LOADING,
} from './actions'

const initialState = {
	loading: false,
}

export default (state = initialState, action = {},) => {
	switch (action.type) {
	case PERSON_LOADING:
		return initialState
	default:
		return state
	}
}
