import NotificationManager from '../../components/common/react-notifications/NotificationManager'

export const VACANCY_LOADING = 'VACANCY_LOADING'

export const setLoadingVacancyAction = (loading) => (dispatch,) => dispatch({
	type: VACANCY_LOADING,
	loading,
},)

export const vacancyCreateAction = (action, variables,) => async (dispatch,) => {
	try {
		dispatch(setLoadingVacancyAction(true))
		const { data, } = await action({ variables, })
		dispatch(setLoadingVacancyAction(false))
		return data.vacancy.id
	} catch (e) {
		NotificationManager.error(
			e.message || '',
			'Проблема создания',
			3000,
			null,
			null,
		)
		dispatch(setLoadingVacancyAction(false))
		return null
	}
}

export const vacancyUpdateAction = (action, variables,) => async (dispatch,) => {
	try {
		dispatch(setLoadingVacancyAction(true))
		const { data, } = await action({ variables, })
		return data.film
	} catch (e) {
		NotificationManager.error(
			e.message || '',
			'Проблема Обновления',
			3000,
			null,
			null,
		)
		dispatch(setLoadingVacancyAction(false))
	}
	dispatch(setLoadingVacancyAction(false))
}
