import storageHelper from '../../helpers/storageHelper'
import { AUTH_REMOVE, AUTH_UPDATE, AUTH_LOADING, USER_UPDATE, } from './actions'

const userEmpty = {
	token: '',
	user: {},
	loading: false,
}

const getInitialUserState = () => {
	const token = storageHelper.get('__token__', 'cookie',) || ''
	if (!token) return userEmpty
	const user = storageHelper.get('__user__',) || {}
	return {
		token,
		user,
		loading: false,
	}
}

const initialState = getInitialUserState()

export default (state = initialState, action = {},) => {
	switch (action.type) {
	case AUTH_UPDATE:
		return {
			...state,
			token: action.token,
		}
	case USER_UPDATE:
		return {
			...state,
			user: action.user,
		}
	case AUTH_LOADING:
		return {
			...state,
			loading: action.loading,
		}
	case AUTH_REMOVE:
		return userEmpty
	default:
		return state
	}
}
