import {
	REWARDS_LOADING,
} from './actions'

const initialState = {
	loading: false,
}

export default (state = initialState, action = {},) => {
	switch (action.type) {
	case REWARDS_LOADING:
		return {
			...state,
			loading: action.loading,
		}
	default:
		return state
	}
}
