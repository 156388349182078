const fallback = {}

const getSendName = (storageType, name,) => {
	switch (storageType) {
	case 'cookie': return name
	default: return `${name}Item`
	}
}

const getSendContent = (storageType, content,) => {
	switch (storageType) {
	case 'cookie': return content
	default: return JSON.stringify(content,)
	}
}

const getContent = (storageType, content,) => {
	switch (storageType) {
	case 'cookie': return content
	default: return content && JSON.parse(content,)
	}
}

export default {
	STORAGE_TYPE_LOCAL: 'localStorage',
	STORAGE_TYPE_SECTION: 'localStorage',
	set (key, value, storage = this.STORAGE_TYPE_LOCAL, options,) {
		const name = getSendName(storage, 'set',)
		const payload = getSendContent(storage, value,)

		try {
			window[storage][name](key, payload,)
		} catch (e) {
			fallback[key] = payload
		}
	},
	get (key, storage = this.STORAGE_TYPE_LOCAL,) {
		try {
			const name = getSendName(storage, 'get',)
			const payload = window[storage][name](key,)
			return getContent(storage, payload,)
		} catch (e) {
			console.log(e,)
			return fallback[key] === undefined ? null : fallback[key]
		}
	},
	remove (key, storage = this.STORAGE_TYPE_LOCAL,) {
		try {
			const name = getSendName(storage, 'remove',)
			window[storage][name](key,)
		} catch (e) {
			delete fallback[key]
		}
	},
}
