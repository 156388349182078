import { combineReducers, } from 'redux'
import auth from './auth/reducer'
import menu from './menu/reducer'
import film from './film/reducer'
import person from './person/reducer'
import news from './news/reducer'
import vacancy from './vacancy/reducer'
import rewards from './rewards/reducer'
import users from './users/reducer'
import customPage from './customPage/reducer'

const reducers = combineReducers({
	auth,
	menu,
	film,
	person,
	news,
	vacancy,
	rewards,
	users,
	customPage,
})

export default reducers
